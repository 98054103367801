/* eslint-disable import/prefer-default-export */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'; // Importez le module de stockage Firebase
import 'firebase/compat/messaging'; // Importez le module de stockage Firebase

const firebaseConfig = {
    apiKey: "AIzaSyDb5Q29rTfosqqqiLCBd5U7vlh-8F66J0g",
    authDomain: "kefservices-eabdd.firebaseapp.com",
    projectId: "kefservices-eabdd",
    storageBucket: "kefservices-eabdd.firebasestorage.app",
    messagingSenderId: "626261667756",
    appId: "1:626261667756:web:8575cf1965f89fd5dd1409",
    measurementId: "G-WE38PDJWVG"
    // configuration pour base de donnees
    // databaseURL: 'https://prochaine-backend-default-rtdb.europe-west1.firebasedatabasex.app', 
};

// Initialisez Firebase avec la configuration
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// Exportez les instances Firestore et Storage
export const firestore = firebase.firestore();
export const storage = firebase.storage(); // Exportez l'instance de stockage Firebase
export const messaging = firebase.messaging(); // Export Firebase Messaging instance

