import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { notify } from "react-push-notification"; // Import notification function
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebase"; // Ensure this path is correct

import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { SocketProvider } from './context/SocketContext';

const VAPID_KEY = process.env.REACT_APP_VAPID_KEY;

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);

  // Request notification permission and get FCM token
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      try {
        const token = await getToken(messaging, { vapidKey: VAPID_KEY });
        console.log("Token generated: ", token);
        // Optionally, send the token to the server here
      } catch (error) {
        console.error("Failed to get token", error);
      }
    } else if (permission === "denied") {
      // eslint-disable-next-line no-alert
      alert("You denied the notification permission");
    }
  }

  useEffect(() => {
    requestPermission();

    // Set up onMessage listener for foreground messages
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      notify({
        title: payload.notification?.title || "New Notification",
        message: payload.notification?.body || "You have a new message",
        theme: "dark", // Optional: theme can be "light" or "dark"
        native: true, // Use native notifications when supported
      });
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <SocketProvider>
          {routing}
        </SocketProvider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
